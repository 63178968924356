<template>
    <router-view></router-view>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    name: 'issues-layout',
    data: () => ({}),
};
</script>

<style></style>
